import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => {
  // -----------------------------initialize state for mobile menu
  const [showMenu, setshowMenu] = useState(false)

  // ----------------------------function to change the state
  const menuActive = () => {
    setshowMenu(!showMenu)
    const body = document.querySelector("body")
    body.classList.toggle("no-scroll")
  }

  return (
    <header className="fixed z-10 top-0 left-0 w-full bg-main md:bg-white">
      {showMenu && (
        <div className="absolute top-20 w-full p-6 bg-main md:hidden">
          <ul>
            <li>
              <Link
                className="font-headline tracking-wider uppercase text-sm text-white"
                to="/#service"
                onClick={menuActive}
              >
                Service
              </Link>
            </li>
            <li className="mt-4">
              <Link
                className="font-headline tracking-wider uppercase text-sm text-white"
                to="/#salon"
                onClick={menuActive}
              >
                Salon
              </Link>
            </li>
            <li className="mt-4">
              <Link
                className="font-headline tracking-wider uppercase text-sm text-white"
                to="/#team"
                onClick={menuActive}
              >
                Team
              </Link>
            </li>
            <li className="mt-4">
              <Link
                className=" font-headline tracking-wider uppercase text-sm text-white"
                to="/galerie"
                onClick={menuActive}
              >
                Looks
              </Link>
            </li>
            <li className="mt-4">
              <Link
                className=" font-headline tracking-wider uppercase text-sm text-white"
                to="/preise"
                onClick={menuActive}
              >
                Preise
              </Link>
            </li>
            {/* <li className="mt-4">
              <a
                className=" font-headline tracking-wider uppercase text-sm text-white"
                href="https://www.e-cut.de/online-buchung/index.php?salon=novoshair_alsfeld"
                rel="noopener noreferrer"
                title="Termin buchen"
                onClick={menuActive}
              >
                Termine
              </a>
            </li> */}

            <li className="mt-4">
              <Link
                className="font-headline tracking-wider uppercase text-sm text-white"
                to="/#kontakt"
                onClick={menuActive}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      )}
      <div className="container px-6  mx-auto py-4 md:py-8 flex justify-between items-center">
        <Link className="hidden md:block" to="/">
          <StaticImage
            src="../images/novus.png"
            width={120}
            quality={95}
            placeholder="blurred"
            alt="Novus Logo"
          />
        </Link>
        <Link className="md:hidden" to="/">
          <StaticImage
            src="../images/novus_white.png"
            width={120}
            quality={95}
            placeholder="blurred"
            alt="Novus Logo"
          />
        </Link>
        <button
          onClick={menuActive}
          className={`hamburger hamburger--collapse md:hidden ${
            showMenu ? "is-active" : null
          }`}
          type="button"
          aria-label="Menü öffnen"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>

        <div className="hidden md:flex justify-between items-center">
          <Link
            className="mr-5 font-headline tracking-wider uppercase text-sm hover:text-main transition duration-300"
            to="/#service"
          >
            Service
          </Link>
          <Link
            className="mr-5 font-headline tracking-wider uppercase text-sm hover:text-main transition duration-300"
            to="/#salon"
          >
            Salon
          </Link>
          <Link
            className="mr-5 font-headline tracking-wider uppercase text-sm hover:text-main transition duration-300"
            to="/#team"
          >
            Team
          </Link>
          <Link
            className="mr-5 font-headline tracking-wider uppercase text-sm hover:text-main transition duration-300"
            to="/galerie"
          >
            Looks
          </Link>
          <Link
            className="mr-5 font-headline tracking-wider uppercase text-sm hover:text-main transition duration-300"
            to="/preise"
          >
            Preise
          </Link>
          {/* <a
            className="mr-5 font-headline tracking-wider uppercase text-sm hover:text-main transition duration-300"
            href="https://www.e-cut.de/online-buchung/index.php?salon=novoshair_alsfeld"
            rel="noopener noreferrer"
            title="Termin buchen"
            onClick={menuActive}
          >
            Termine
          </a> */}
          <Link
            className="mr-9 font-headline tracking-wider uppercase text-sm hover:text-main transition duration-300"
            to="/#kontakt"
          >
            Kontakt
          </Link>
          <a
            href="https://www.instagram.com/novus_h.c/"
            rel="noopener noreferrer"
            title="Novus Instagram"
            target="_blank"
            style={{ height: "17px" }}
          >
            <StaticImage
              src="../images/icons/instagram_dark.svg"
              height={17}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Novus Logo"
              placeholder="blurred"
            />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
