import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Header from "./header"

import Instagram from "../images/icons/instagram.svg"
import Mail from "../images/icons/email.svg"

const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LayoutContainer className="antialiased">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <footer className="bg-main py-7 md:mt-10">
        <div className="container px-6 mx-auto">
          <div className=" flex flex-col justify-between md:flex-row md:items-center">
            <div className=" mb-8 md:mb-0">
              <StaticImage
                src="../images/novus_white.png"
                width={80}
                quality={95}
                placeholder="blurred"
                alt="Novus Logo"
              />
            </div>

            <div className="flex content-center mb-8 md:mb-0">
              <a
                href="https://www.instagram.com/novus_h.c/"
                rel="noopener noreferrer"
                title="Novus Instagram"
                target="_blank"
                className="w-max"
              >
                <img
                  src={Instagram}
                  alt="Instagram Icon"
                  style={{
                    margin: "0 1rem 0 0",
                    height: "18px",
                    width: "35px",
                  }}
                />
              </a>
              <a
                href="mailto:brassel.kim@gmx.de"
                title="Mail schreiben"
                className="w-max"
              >
                <img
                  src={Mail}
                  alt="Mail Icon"
                  style={{
                    margin: "0 1rem 0 0",
                    height: "16px",
                    width: "35px",
                  }}
                />
              </a>
            </div>
            <div className="w-max">
              <a
                href="https://connect2.studio/"
                target="_blank"
                rel="noopener noreferrer"
                title="Zu connect2 Studio"
              >
                <StaticImage
                  src="../images/logo_white.svg"
                  width={35}
                  quality={95}
                  alt="connect2 Studio Logo"
                />
              </a>
            </div>
          </div>
          <div className="mt-4">
            <Link
              className="mr-5 font-headline text-white tracking-wider uppercase text-xs"
              to="/impressum"
            >
              Impressum
            </Link>
            <Link
              className="mr-5 font-headline text-white tracking-wider uppercase text-xs"
              to="/datenschutz"
            >
              Datenschutz
            </Link>
          </div>
        </div>
      </footer>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
